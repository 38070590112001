import React, { useMemo } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import { Link } from "gatsby"
import Layout from '../components/layout';

function Guarantee() {
  return (
    <Layout>
      <Typography component="h2" variant='h2'>
        Happyness Guarantee
      </Typography>
      <Typography>

        
      </Typography>

      <Typography
        sx={{
          textAlign: "center"
        }}>
        <Link to="/">Click to Go back Home</Link>
      </Typography>

    </Layout>
  )
}

export default Guarantee;